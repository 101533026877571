<template>
  <div :class="$style.SocialIcons">
    <NuxtLink v-for="item in socialItems" :key="item.title" :to="item.link">
      <component :is="item.icon" :class="[$style.icon, mods]" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { SvgoIconTelegram, SvgoIconVk } from "#components";

type Color = "blue" | "white";

const props = defineProps<{
  color: Color;
}>();

const style = useCssModule();

const mods = computed(() => ({
  [style[props.color]]: true,
}));

const socialItems = [
  {
    title: "Telegram",
    link: "https://t.me/coinstartnews",
    icon: defineComponent(SvgoIconTelegram),
  },
  {
    title: "Vk",
    link: "https://vk.com/public176817981",
    icon: defineComponent(SvgoIconVk),
  },
];
</script>

<style lang="scss" module>
.SocialIcons {
  display: flex;
  align-items: center;
  gap: 1rem;
  line-height: 1;
}

.blue {
  color: $brand-blue;

  &:hover {
    color: $dark-blue;
  }
}

.white {
  color: var(--footer-logo);

  &:hover {
    color: $base-100;
  }
}

.icon {
  width: 3.2rem;
  height: 3.2rem;
  transition: color $base-transition;
}
</style>
